import React, {useState, useEffect} from 'react'
import {Container} from 'semantic-ui-react'
import Seo from '../../components/SEO'
import Layout from '../../components/Layout'

import * as styles from '../styles/pages.module.css'
import MyAccountHeader from '../../components/Pages/MyAccount/header'

import ManageAddress from '../../components/Pages/MyAccount/manageAddress'
const Negosyo = require('../../../lib/negosyo')

const MyAccountAddressPage = ({location}) => {
	const [user, setUser] = useState(undefined);
	useEffect(() => {
		Negosyo.isLoggedIn()
		.then((data)=>{
			if(data!==false) {
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					setUser(user);
				}).catch((err)=>{
				});
			}
		})

	}, [])

	return (
		<Layout location={location}>
			<Seo title={"Manage Address"} meta={[{name: `robots`, content: `noindex`}]} />
			<Container className={styles.container}>
				<MyAccountHeader location={location} />
				{user &&
					<ManageAddress
						user={user}
					/>
				}
			</Container>
		</Layout>
	)
}

export default MyAccountAddressPage
