import React, { Component } from 'react'
import {
	Button,
	Message
} from 'semantic-ui-react'
import * as styles from './styles/retryButton.module.css'




class RetryButton extends Component {

	render() {
		const {
			retryFunc,
			retryMessage,
			errorMessage,
			loading,
			buttonName,
			messageName
		} = this.props
		return (
			<div className={`${styles.text} ${styles.centerText}`}>
				{errorMessage!==undefined && (
					<Message negative className={styles.msg}>
						<Message.Header name={messageName ? messageName:"retryButtonErrorMsg"}>
							{errorMessage}
						</Message.Header>
					</Message>
				)}
				<Button
					icon="redo"
					size="big"
					className={styles.iconButton}
					onClick={()=>{
						retryFunc()
					}}
					disabled={loading}
					name={buttonName ? buttonName:"retryButton"}
				/>
				<br/>
				<br/>
				{retryMessage===undefined ? "Try Again":retryMessage}
			</div>
		)
	}
}
export default RetryButton

