import React, {useState, useEffect}from 'react'
import {
	Button,
	Card,
	Grid,
	Icon,
	Loader,
	Message,
} from 'semantic-ui-react'
import AddressForm from '../Forms/addressFormMod'
import * as styles from './styles/address.module.css'
import {
	deleteAddressColor,
	editAddressColor,
} from '../../globalVar'
import RetryButton from '../RetryButton'

const Negosyo = require('../../../lib/negosyo')
const Helper = require('../../helper')

/*handleEdit prop comment
 *used to clean up parent component(this) so it will only have addressForm
 *when pressing edit button on address cards
 */

/*handleBack prop comment
 *used for back button in showAddress when showing addressForm for edit
 *and for user successfully edits an address
 */
const ShowAddress = ({user, handleEdit, handleBack, shown, handleChoose }) => {
	const [address, setAddress] = useState([])
	const [loading, setLoading] = useState(false)//for loading address list
	const [editLoading, setEditLoading] = useState(false) //for loading when editing address
	const [editMode, setEditMode] = useState(false)
	const [chooseMode, setChooseMode] = useState(false)
	const [uuid, setUuid] = useState(null)
	const [addressValues, setAddressValues] = useState(null)
	const [type, setType] = useState(null)
	const [getAddressListError, setGetAddressListError] = useState(null)
	const [getAddressApiError, setGetAddressApiError] = useState(null)

	const fetchData = () => {
		setLoading(true);
		setGetAddressListError(null);
		Negosyo.listAddress(user.username).then((response)=>{
			if(response.status==="OK") {
				setAddress(response.data)
			}else if(response.status==="None") {
				setAddress([])
			}else {
				setGetAddressListError("Error occured. Please try again.");
			}
			setLoading(false)
		}).catch((err)=>{
			if(
				err.message.toLowerCase()==="failed to fetch" || //chrome and edge
				err.message.toLowerCase()==="networkerror when attempting to fetch resource" //firefox but unconfirmed
			) {
				setGetAddressListError("Problem occured. Please try again");
			}else {
				setGetAddressListError("Error occured. Please try again");
			}
			setLoading(false)
		})
	}

	useEffect(fetchData, [])



	/*maybe should change this to call fetchData() again to get the more up to date list rather than just filtering from the previously gotten list*/
	const handleDelete = (uuid) => {
		setGetAddressApiError(null);
		Negosyo.deleteAddress(user.username, uuid).then((response)=>{
			if(response.status==="OK") {
				setUuid(null)
				const tempList = address.filter((tempAddress)=>{
					return tempAddress.uuid !== uuid
				})
				setAddress(tempList)
			}else {
				setGetAddressApiError("Error occured. Please try again.");
				setUuid(null)
			}
		}).catch((err)=>{
			if(
				err.message.toLowerCase()==="failed to fetch" || //chrome and edge
				err.message.toLowerCase()==="networkerror when attempting to fetch resource" //firefox but unconfirmed
			) {
				setGetAddressApiError("Problem occured. Please try again");
			}else {
				setGetAddressApiError("Error occured. Please try again");
			}
			setUuid(null)
		})
	}

	const getAddress = (id, type) => {
		setGetAddressApiError(null);
		Negosyo.getAddress(user.username, id).then((response)=> {
			if(response.status==="OK") {
				if(type==="edit") {
					setEditMode(true)
					handleEdit()
				}else if(type==="choose") {
					setChooseMode(true)
				}
				let temp = []

				temp = temp.concat(response.data.components)
				temp = temp.concat(response.data.streetaddress.components)
				const addressFormValues = Helper.handleComponentToAddress(temp);
				setAddressValues(addressFormValues)
			}else {
				setGetAddressApiError("Error occured. Please try again.");
				setUuid(null)
			}

		}).catch((err)=>{
			if(
				err.message.toLowerCase()==="failed to fetch" || //chrome and edge
				err.message.toLowerCase()==="networkerror when attempting to fetch resource" //firefox but unconfirmed
			) {
				setGetAddressApiError("Problem occured. Please try again");
			}else {
				setGetAddressApiError("Error occured. Please try again");
			}
			setUuid(null)
		})
	}

	const editAddress = ({address}) =>{
		setGetAddressApiError(null);
		Helper.handleFloor(address)
		const components = Helper.handleComponentCreation(address)
		setEditLoading(true)
		Negosyo.updateAddress(user.username, uuid, components).then((data)=>{
			if(data.status==="OK") {
				handleBack()
				fetchData()
				setUuid(null)
				setEditMode(false)
				setAddressValues(null)

			}else {
				setGetAddressApiError("Error occured. Please try again.");
			}
			setEditLoading(false)
		}).catch((err)=>{
			if(
				err.message.toLowerCase()==="failed to fetch" || //chrome and edge
				err.message.toLowerCase()==="networkerror when attempting to fetch resource" //firefox but unconfirmed
			) {
				setGetAddressApiError("Problem occured. Please try again");
			}else {
				setGetAddressApiError("Error occured. Please try again");
			}
			setEditLoading(false)
		})
	}

	const passAddress = ({address}) => {
		setGetAddressApiError(null) //don't know why there would be api error here but just in case added this code in case I forgot something, should be safe to delete
		setUuid(null)
		setChooseMode(false)
		setAddressValues(null)
		handleChoose(address)
	}

	/*address.contactName and address.contact isn't really being used for now so the content is placeholders, should comment this out in the near future to be sure*/
	const mapAddressInfoCard = (address) => {
		return (
			<Card key={address.uuid}>
				<Card.Content className={styles.card}>
					{address.contactName && (
						<Card.Header >
							{"Name"}
						</Card.Header>
					)}

					{address.contact && (
						<Card.Meta>
							{"09XXXXXXXXX"}
						</Card.Meta>
					)}
					<Card.Description>
						{address.name}
					</Card.Description>
				</Card.Content>
				<Card.Content extra>
					<Grid>
						<Grid.Column width ={8}>
							<Button
								content=<Icon name={"pencil"} className={styles.icon}/>
								color={editAddressColor}
								onClick={()=>{
									setUuid(address.uuid)
									setType("edit")
									getAddress(address.uuid, "edit")

								}}
								disabled={uuid!==null ? true:false}
								loading={address.uuid===uuid && type==="edit"}
								fluid
								className={styles.button}
								name={"editAddressButtons"}
							/>
						</Grid.Column>
						<Grid.Column width ={8}>
							<Button
								content=<Icon name="trash" className={styles.icon}/>
								color={deleteAddressColor}
								onClick={()=>{
									setUuid(address.uuid)
									setType("delete")
									handleDelete(address.uuid)
								}}
								loading={address.uuid===uuid && type==="delete"}
								disabled={uuid!==null ? true:false}
								fluid
								className={styles.button}
								name={"deleteAddressButtons"}
							/>
						</Grid.Column>
					</Grid>
				</Card.Content>

			</Card>
		)
	}

	return (
		<React.Fragment>
			{loading ? (
				<Loader active={loading} className={styles.loader} inline="centered"/>
			):(
				<React.Fragment>
					{getAddressListError ? (
						<Grid.Column width={16}>
							<RetryButton
								retryFunc={fetchData}
								errorMessage={getAddressListError}
								buttonName={"getAddressListButton"}
								messageName={"getAddressListMsg"}
							/>
						</Grid.Column>
					):(
						<React.Fragment>
							{!(address.length>0) ? (
								<Grid.Column width={16}>
									<Message warning className={styles.msg}>
										<Message.Header name={"noAddressMsg"}>No Address assigned</Message.Header>
									</Message>
								</Grid.Column>
							): (
								<React.Fragment>
									{ editMode || chooseMode ? (
										<Grid.Column width={16}>
											{addressValues && (
												<React.Fragment>
													<AddressForm
														handleSubmit={editMode? editAddress:passAddress}
														buttonMessage={"Proceed"}
														values={addressValues}
														backFunc={()=>{
															if(editMode) {
																handleBack()
																setEditMode(false)
															}else if(chooseMode) {
																setChooseMode(false)
															}
															setUuid(null)
															setAddressValues(null)
															setGetAddressApiError(null)
														}}
														loading={editLoading}
														backDisabled={editLoading}
														error={getAddressApiError}
													/>
												</React.Fragment>
											)}

										</Grid.Column>

									):(
										<React.Fragment>
											{getAddressApiError && (
												<Grid.Column width={16}>
													<Message negative className={styles.msg}>
														<Message.Header name={"AddressApiMsg"}>{getAddressApiError}</Message.Header>
													</Message>
												</Grid.Column>
											)}
											<Grid.Column width={16} >
												<Card.Group
													itemsPerRow={shown}
													stackable
												>
													{address.map(mapAddressInfoCard)}
												</Card.Group>
											</Grid.Column>
										</React.Fragment>
									)}

								</React.Fragment>
							)}
						</React.Fragment>
					)}

				</React.Fragment>
			)}



		</React.Fragment>
	)
}
export default ShowAddress


/* for handle choosing of address in cart before the switch to segmented radio button options
				{handleChoose && (
					<Card.Content>
						<Button
							content="choose this"
							fluid
							color={confirmationColor}
							onClick={()=>{
								setUuid(address.uuid)
								setType("choose")
								getAddress(address.uuid, "choose")
							}}
							disabled={uuid}
							loading={address.uuid===uuid && type==="choose"}
							className={styles.button}
						/>
					</Card.Content>
				)}
*/




/*
//might just merge this with getAddress and just pass a 2nd variable to differentiate between the two
	const passAddress = (id) => {
		Negosyo.getAddress(user.username, id).then((response)=> {
			//console.log(response)

			if(response.status==="OK") {
				let temp = []

				temp = temp.concat(response.data.components)
				temp = temp.concat(response.data.streetaddress.components)
				//console.log(temp)
				const addressFormValues = Helper.handleComponentToAddress(temp);
				handleChoose(addressFormValues)
			}else {
				//alert("sorry there was a problem getting your address")
				setUuid(null)
			}

		}).catch((err)=>{
			//alert("sorry there was a problem getting your address")
			setUuid(null)
			//console.log(err)
		})
	}
*/

	/* formerly used for choose button function

	setUuid(address.uuid)
	setType("choose")
	passAddress(address.uuid)
	*/

