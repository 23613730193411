import React, {Component} from "react"
import {Button, Grid, Header } from 'semantic-ui-react'
import ShowAddress from '../../Address/showAddress'
import AddressForm from '../../Forms/addressFormMod'
//import AddressForm from '../../Forms/addressForm'
import * as styles from '../styles/pages.module.css'
import {
	confirmationColor
} from '../../../globalVar'
const Helper = require('../../../helper')
const Negosyo = require('../../../../lib/negosyo')


export default class ManageAddress extends Component {
	state={
		stage:"Manage Address",
		shown:2,
		error:undefined,
		loading:false,
		updateLoading:false //for edit and delete address
	}


	addAddress = ({address}) => {

		this.setState({
			loading:true,
			error:undefined
		})
		Helper.handleFloor(address)
		const components = Helper.handleComponentCreation(address)
		if(this.props.user) {
			Negosyo.addAddress(this.props.user.username, components).then((data)=>{
				if(data.status==="OK") {
					this.setState({
						loading:false,
						stage:"Manage Address"
					})
				}else {
					this.setState({
						error:"Error occured. Please try again.",
						loading:false
					})
				}
			}).catch((err)=>{
				let errorMessage;
				if(
					err.message.toLowerCase()==="failed to fetch" || //chrome and edge
					err.message.toLowerCase()==="networkerror when attempting to fetch resource" //firefox but unconfirmed
				) {
					errorMessage = "Problem occured. Please try again";
				}else {
					errorMessage = "Error occured. Please try again" ;
				}
				this.setState({
					error:errorMessage,
					loading:false
				})
			})
		}

	}

	render() {
		const {
			stage,
			shown,
			error,
			loading,
			updateLoading
		} = this.state
		const {addAddress} = this
		let user
		if(this.props.user) {
			user = this.props.user
		}
		return(
			<React.Fragment>
				<Header className={styles.header}>{stage}</Header>
				<br/>
				{ ( stage==="Manage Address" || stage==="Edit Address" ) && (
					<React.Fragment>
						{ user && (
							<Grid>
								<ShowAddress
									user={user}
									PrepEdit={(editLoading)=>{
										this.setState({updateLoading:editLoading})
									}}
									handleEdit={(uuid)=>{
										this.setState({stage:"Edit Address"})
									}}

									handleBack={()=>{
										this.setState({stage:"Manage Address"})
									}}
									shown={shown}
								/>
							</Grid>
						)}
						{stage === "Manage Address" &&(
							<React.Fragment>
								<Button
									content="Add new Address"
									color={confirmationColor}
									onClick={()=>{
										this.setState({
											stage:"Add Address",
											error:undefined
										})
									}}
									disabled={updateLoading}
									className={styles.form}
									name={"addAddressButton"}
								/>
							</React.Fragment>
						)}
					</React.Fragment>
				)}
				{ stage==="Add Address" && (
					<React.Fragment>

						<AddressForm
							//values={this.props.initialAddress} /*commented this out because we don't have name and contact in the address form for now*/
							handleSubmit={addAddress}
							billing={false}
							buttonMessage={"Add New Address"}
							loading={loading}
							error={error}
							backFunc={()=>{
								this.setState({
									stage:"Manage Address",
									error:undefined
								})
							}}
							backDisabled={loading}
						/>


					</React.Fragment>

				)}


			</React.Fragment>
		)
	}
}