/*
 *For common functions that will be reused as is by a few components and
 *the resources(variables and such) needed for them to function properly
*/


//addressKey:componentType
const countryComponents ={
	Philippines: {
		province:"locationLevel3",
		city:"locationLevel4",
		barangay:"locationLevel5",
		locality:"locationLevel6"
	},
}

//addressKey:componentType
const addTypes = {
	//type:"type",
	unitNumber:"unitNumber",
	officeName:"officeName",
	floor:"floorNumber",
	postalCode:"postalCode",
	country:"locationLevel1",
	street:"streetName",
	buildingName:"buildingName",
	//name:"contactName",
	//contact:"contact",
	landmark:"landmark",
	restriction:"restriction",
	notes:"notes",
	lat:"latitude",
	lng:"longitude",
	locationcode:"locationcode"
}



const componentToInput = {
	//type:"type",
	unitNumber:"unitNumber",
	officeName:"officeName",
	floorNumber:"floor",
	postalCode:"postalCode",
	locationLevel1:"country",
	streetName:"street",
	buildingName:"buildingName",
	locationLevel3:"province",
	locationLevel4:"city",
	locationLevel5:"barangay",
	locationLevel6:"locality",
	//contactName:"name",
	//contact:"contact",
	landmark:"landmark",
	restriction:"restriction",
	notes:"notes",
	longitude:"lng",
	latitude:"lat",
	locationcode:"locationcode",
}

// for now here rather than in addressForm since I'm not sure what
const handleComponentCreation = (address) => {
	const components = [];


	for( let key of Object.keys( addTypes ) ) {
		if( ( address[key] !== '' || key==="unitNumber" || key==="buildingName" ) && address[key] !== undefined ) {

			components.push({
				componentType:addTypes[key],
				componentValue:address[key]
			});
		}
	}
	for( let key of Object.keys( countryComponents ) ) {
		if( key===address.country ) {
			for( let key2 of Object.keys( countryComponents[key] ) ) {
				if( address[key2] !== '' && address[key2] !== undefined && address[key2]!==null) {
					components.push({
						componentType:countryComponents[key][key2],
						componentValue:address[key2]
					});
				}
			}
		}
	}
	return components;
}

//converts components to address form obj we can feed to addressForm as its initial value
const handleComponentToAddress = (components) => {
	const addressFormValue = {};
	for( let i = 0; i<components.length;i++ ) {
		for(let key in componentToInput) {
			if(components[i].componentType===key) {
				addressFormValue[componentToInput[key]] = components[i].componentValue;
				break;
			}
		}
	}
	if(addressFormValue.floor < 0 ) {
		addressFormValue.floor = addressFormValue.floor * -1
		addressFormValue.basement = "Basement";
	}else {
		addressFormValue.basement = "Floor";
	}
	return addressFormValue;
}


const handleFloor = (address) => {
	address.floor = address.basement==="Basement" ? address.floor*-1 : address.floor;
}

module.exports = {
	handleComponentCreation,
	handleFloor,
	handleComponentToAddress
}